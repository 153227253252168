import Vue from 'vue'
import Vuex, { Store } from 'vuex'
Vue.use(Vuex);
import {regions} from '@/mixins/regions.js'
import {auth,usersCollection,functions,db} from '@/services/firebaseConfig.js'
import { linckard_model,profile_model } from '../mixins/data';
import { isEmptyObject } from 'jquery';
import mixpanel from '@/plugins/vue-mixpanel'

const getDefaultState = () => {
  return {
      currentCheckedSobre:0,
      locales: ['en', 'es'],
      locale: 'en',
      loading:true,
      activeTab:0,
      mode:'mp',
      currentUser:null,
      userProfile:null,
      contactView:'directorio',
      orderBy:'relevance',
      menuMobileEditor:true,
      admin_families:[
        'Nunito:300,400,500,600,700,800,900',
        'Open+Sans:300,400,500,600,700,800,900',
        'Oswald:300,400,500,600,700,800,900',
      ],
      families: [
        'Nunito:300,400,500,600,700,800,900',
        'Open+Sans:300,400,500,600,700,800,900',
        'Baloo+Tammudu+2:300,400,500,600,700,800,900',
        'Oswald:300,400,500,600,700,800,900',
        'Roboto+Slab:300,400,500,600,700,800,900',
        'Varela+Round:300,400,500,600,700,800,900',
        'Rubik:300,400,500,600,700,800,900',
        'Merriweather:300,400,500,600,700,800,900',
        'Playfair+Display:300,400,500,600,700,800,900',
        'Playfair Display:300,400,500,600,700,800,900',
        'Raleway:300,400,500,600,700,800,900',
        'Cinzel:300,400,500,600,700,800,900',
        'Laila:300,400,500,600,700,800,900',
        'Roboto:300,400,500,600,700,800,900',
        'Thasadith:300,400,500,600,700,800,900',
        'Roboto+Condensed:300,400,500,600,700,800,900',
        'Abril+Fatface:300,400,500,600,700,800,900',
        'Coda:300,400,500,600,700,800,900',
        'Overlock:300,400,500,600,700,800,900',
        'Arima+Madurai:300,400,500,600,700,800,900',
        'Pacifico:300,400,500,600,700,800,900',
        'Dancing+Script:300,400,500,600,700,800,900',
        'Amatic+SC:300,400,500,600,700,800,900',
        'Parisienne:300,400,500,600,700,800,900',
        'Sofia:300,400,500,600,700,800,900',
        'Roboto+Mono:300,400,500,600,700,800,900',
        'PT+Mono:300,400,500,600,700,800,900',
        'Ubuntu+Mono:300,400,500,600,700,800,900',
        'Space+Mono:300,400,500,600,700,800,900',
        'IBM+Plex+Mono:300,400,500,600,700,800,900',
        'IBM+Plex+Sans:300,400,500,600,700,800,900',
        'Fira+Sans:300,400,500,600,700,800,900',
        'Sarabun:300,400,500,600,700,800,900',
        'Krub:300,400,500,600,700,800,900',
        'Prompt:300,400,500,600,700,800,900',
        'Noto+Sans+Thai:300,400,500,600,700,800,900',
        'Sriracha:300,400,500,600,700,800,900',
        'Baloo+Tammudu+2:300,400,500,600,700,800,900'
      ],
      gfonts: ["Roboto Slab","Baloo Tammudu 2","Merriweather","Open Sans","Noto Sans Thai","Krub","Prompt","Sarabun","Sriracha","Playfair Display","Raleway","Cinzel","Laila","Roboto","Thasadith","Oswald","Roboto Condensed","Nunito","Abril Fatface","Coda","Overlock","Arima Madurai","Pacifico","Dancing Script","Amatic SC","Parisienne","Sofia","Roboto Mono","PT Mono","Ubuntu Mono","Space Mono","IBM Plex Mono","IBM Plex Sans","Fira Sans"],

      uploadBus:[],

      //Lincard?
      mainComp:'main_Linckards',
      infoMode:'content',
      signaComp:"leftCol_SD",
      sobreComp:'leftColSobreDA',
      //partner
      isInPartnerView:false,

      //Undoredo
      isLiveEditing:false,

      signature:{
        active:{},
        versions:[],
        isSaving:false,
        index:0
      },
      editor:{
        active:null,
        versions:[],
        isSaving:false,
        index:0
      },
      //Directorio
      activeSector:'',
      activeView:'',
      previousRoute: null,
      currentType: null,
  }
}
// initial state
const state = getDefaultState()


const createStore = () => {
  return new Vuex.Store({
    state,
    getters: {
      userProfile: (state, getters) => {
        return state.userProfile
      },
      editingSignature: (state, getters) => {
        return state.signature.active
      },
      signatureEdited: (state, getters) => {
        return state.signature.versions.length>1
      },

      editingLinckard: (state, getters) => {
        return state.editor.active
      },
      hasBeenEdited: (state, getters) => {
        return state.editor.versions.length>1
      },
      countryCode: (state) => {
        if(state.userProfile && state.userProfile.account_details.account_country)return regions.find(x => x.label == state.userProfile.account_details.account_country).countryShortCode
        return "US"
      },
      previousRoute: (state) => state.previousRoute,
    },
    mutations:{
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      updateStatusLinkcard (state,element){
        let status=element.status
        console.log(status)
        element=element.element
        let index = state.userProfile.linckards.findIndex(function(i){return i.id == element.id})
        state.userProfile.linckards[index].status=status

      },
      updateStatusSector (state,element){
        let status=element.status
        element=element
        let index = state.userProfile.linckards.findIndex(function(i){return i.id == element.id})
        state.userProfile.linckards[index].status=status
      },
      setMainComp(state,comp){
        state.mainComp=comp
      },
      setCurrentUser (state, user) {
        state.currentUser = user
      },
      SET_MODE (state, mode) {
          state.mode = mode
        
      },
      SET_LANG (state, locale) {
        if (state.locales.includes(locale)) {
          state.locale = locale
        }
      },
      setActiveView(state, val) {

        state.activeSector=''
        state.activeView=val
      },
      setUserProfile(state, user) {
          state.userProfile = user
      },
      
      editPushInit(state,{linckard}){
        //Aca entro la primera vez, a editar
        if(!linckard.tags)linckard.tags=[]
        state.infoMode='content'
        if(!linckard.seo)linckard.seo={img:'',title:'',desc:''}
        if(!linckard.tracking || isEmptyObject(linckard.tracking)){
          linckard.tracking={}
          linckard.tracking.pixel_id=''
          linckard.tracking.analytics_id=''
          linckard.tracking.gtm_id=''
        }
        if(!linckard.locale)linckard.locale=''
        if(!linckard.locale)linckard.sobre_active=true
        if(linckard.directory == undefined)linckard.directory=true
        

        if(linckard.tabs){
          //if(linckard.tabs[linckard.tabs.length-1].name=='Extra')linckard.tabs.splice(linckard.tabs.length-1,1)
        }else if(!linckard.tabs){/*los labels son solo unternos*/
          if(linckard.loyalty_id>=0)linckard.tabs=[{id:0,name:'Membre',active:true},{id:1,name:'Sobre',active:true},{id:2,name:'Info',active:true}]
          else if(!linckard.isLanding)linckard.tabs=[{id:0,name:'Info',active:true},{id:1,name:'Sobre',active:true},{id:2,name:'Compartir',active:true},{id:3,name:'Extra',active:true}]
          else if(linckard.isLanding)linckard.tabs=[{id:0,name:'Sobre',active:true},{id:1,name:'Info',active:true},{id:2,name:'Compartir',active:true}]
        } 
        state.editor.active=linckard
        state.editor.old=JSON.parse(JSON.stringify(linckard));
        state.editor.versions=[]
        state.uploadBus=[]
        state.editor.versions.push(JSON.parse(JSON.stringify(linckard)))
        state.editor.index=1
      },
      signaPushInit(state,{signature}){
        if(!signature.firma_new){
          if(signature.firma)delete signature.firma
          signature.firma_new={img:""}
        }
        state.signature.active=JSON.parse(JSON.stringify(signature));
        state.signature.old=JSON.parse(JSON.stringify(signature));
        state.signature.versions=[]
        state.uploadBus=[]
        state.signature.versions.push(JSON.parse(JSON.stringify(signature)))
        state.signature.index=1
      },
      changeActiveEdit(state,newVal){
        if(JSON.stringify(state.editor.versions[state.editor.versions.length-1])===JSON.stringify(newVal))return;
        state.editor.versions.push(JSON.parse(JSON.stringify(newVal)))
        state.editor.index++
      },
      changeActiveSignature(state,newVal){
        if(JSON.stringify(state.signature.versions[state.signature.versions.length-1])===JSON.stringify(newVal))return;
        state.signature.versions.push(JSON.parse(JSON.stringify(newVal)))
        state.signature.index++
      },
      
      resetColaEditar(state){
        state.editor.index=1
        state.editor.versions=[]
        state.uploadBus=[]
      },
      resetSaveLinckardVersioning(state){
        state.editor.isSaving=true;
        //reset
        state.editor.index=1
        state.editor.versions=[]
        state.uploadBus=[]

        //fin reset
        state.editor.old=JSON.parse(JSON.stringify(state.editor.active))
        state.editor.versions.push(JSON.parse(JSON.stringify(state.editor.active)))
        state.editor.isSaving=false;
      },
      resetSaveSignatureVersioning(state){
        state.signature.isSaving=true;
        //reset
        state.signature.index=1
        state.signature.versions=[]
        state.uploadBus=[]

        //fin reset
        state.signature.old=JSON.parse(JSON.stringify(state.signature.active))
        state.signature.versions.push(JSON.parse(JSON.stringify(state.signature.active)))
        state.signature.isSaving=false;
      },
      setPreviousRoute(state, route) {
        state.previousRoute = route
      },
      setCurrentType(state, type) {
        state.currentType = type;
      },
      
      clearCurrentType(state) {
        state.currentType = null;
      },
    },
    actions:{
        async signOut({commit}) {
            await auth.signOut();
            commit('resetState')
            commit('setUserProfile', null)
            commit('setCurrentUser', null)
        },
        
        async fetchUserProfile({ commit, state }) {
          let dis=this
          if(state.userProfile)return
          if(!state.currentUser || !state.currentUser.uid)return;
          var uid=state.currentUser.uid;
          await usersCollection.doc(uid).get().then(async res => {
              
              var user=res.data();
              if(user)user.uid=uid
              else {
                await auth.signOut()
                commit('setUserProfile', null)
                commit('setCurrentUser', null)
              }
              if(user && (!user.signature || !user.signature.banner))user.signature=profile_model.signature
              if(user && user.signature.banner && !user.signature.banner.width)user.signature.banner.width=400
              
              if(this.$util.isEnterprise(user)){
                try{
                  let lincs=await db.collection("linkcards").where('profile_username','==',user.username).orderBy('id','asc').get().then(async querySnapshot =>{ return querySnapshot.docs})
                  let tolincs=[]
                  for(let i in lincs) tolincs.push(lincs[i].data())
                  if(tolincs.length>0){
                    delete user.linckards
                    user.linckards=tolincs
                  }
                  console.log('trajo enterprise',user.linckards.length)
                
                }catch(e){
                  console.log(e)
                }
                
              }
              commit('setUserProfile', user)

              if(this.$util.getBusinessCards().length>0 && this.$util.getLandingPages().length==0)commit('SET_MODE', 'bc')//modo bc solo asi
              //Calls para despertar
              try{
                let templates=await db.collection("templates").where("from","==",user.uid).get().then(async querySnapshot =>{ return querySnapshot.docs})
                state.templates=[]
                for(let i in templates) state.templates.push(templates[i].data())

              }catch( e){
                console.log(e)
              }
              try{
                functions.httpsCallable('get_stripe_checkout')({price_id:'#',locale:state.locale,success_url:'/account#success',cancel_url:'/account#plans'}).catch(function() {  })
                functions.httpsCallable('get_stripe_portal')({customer_id:'#',locale:state.locale,return_url:'/account#success',cancel_url:'/account#plans'}).catch(function() {  })
              }catch(e){
                console.log(e)
              }
              
              
              /*
              let ref=db.collection("users")
              ref=ref.orderBy('account_details.account_date','desc')
              if(ultimo)ref=ref.startAt(ultimo)
              ref=ref.limit(1000)
              await ref.get().then(async querySnapshot =>{
                  console.log('se trajeron ',querySnapshot.size)
                  
                  for (var i in querySnapshot.docs) {
                      let doc = querySnapshot.docs[i]
                      let user=doc.data()
                      console.log(user.account_details.account_date.length)
                      if(user.account_details.account_date.length<12)console.log(user.uid,user.account_details.account_date)
                      ultimo=account_details.account_date
                  }

              });*/

              //let days=3 //notis de los ultimos 3 dias
              /*
              var d = new Date()
              d.setDate(d.getDate() - days)
              d=d.getTime();
              await db.collection("notis").where('to.uid','==',uid).where('date_time',">=",d).where('deleted','==',false).orderBy('date_time','desc').get()
              .then(async snapq => { 
                state.userProfile.notis=snapq.size 
                console.log(snapq.size)
              })*/
              
              return user
          }).catch(async res => {
            dis.$util.notif_error(res.message)
            return null
          });

        }
    }
  })
}

export default createStore

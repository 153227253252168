<template>
    <section id="pricing" style="margin: 0px; padding: 0px;">
        <iframe :src="iframeSrc" width="620" height="370" frameborder="0" scrolling="no" style=""
            @load="onIframeLoad = true" v-if="showIframe" ref="iframe"></iframe>
        <div style="display: flex; justify-content: center; gap: 20px; margin: 20px 0;">
            <button @click="gotoCheckout('monthly')" class="btn btn-primary btn-initial btn-rounded">
                Upgrade Monthly
            </button>
            <button @click="gotoCheckout('yearly')" class="btn btn-primary btn-initial btn-rounded">
                Upgrade Yearly
            </button>
        </div>
    </section>
</template>


<script>

import { loadStripe } from '@stripe/stripe-js';
import { stripe_public, functions } from '@/services/firebaseConfig.js'
import { planes_businesscards } from '@/mixins/stripe.js'
import { stripePlans } from '@/plugins/utils.js';

export default {
    props: ['place'],
    middleware: 'auth-check',
    head() { return this.$util.simpleMetaHead() },
    data() {
        return {
            loading: false,
            lugar: this.place,
            planes_businesscards: planes_businesscards,
            getUser: this.$store.state.userProfile,
            annual: true,
            sessionId: '',
            banners: {
                'bc': "https://linkcard.app/banner-upgrade-bc/",
                'stats': "https://linkcard.app/banner-upgrade-bc/",
                'leads': "https://linkcard.app/banner-upgrade-bc/",
                'pas': "https://linkcard.app/banner-upgrade-micro-landings/",
                'lib': "https://linkcard.app/banner-upgrade-link-in-bio/",
                'loyaltys': "https://linkcard.app/banner-upgrade-loyalty/",
            },
            plans: stripePlans,
            showIframe: false,
            iframeLoaded: false,
        }
    },
    computed: {
        iframeSrc() {
            const url = this.banners[this.place] || this.banners['bc'];
            return url;
        }
    },
    methods: {
        async gotoCheckout_client(product) {
            var dis = this
            let price = product.plans[product.plans.length > 1 && this.annual ? 1 : 0]
            this.loading = true

            let stripe = await loadStripe('pk_live_KM0WAadM3l0YbDQtCkvnaSLE00OuTdfw3Q');
            stripe.redirectToCheckout({ mode: 'subscription', lineItems: [{ price: price.plan_id, quantity: 1 }], locale: dis.$store.state.locale, successUrl: 'https://my.linkcard.app/account#success', cancelUrl: 'https://my.linkcard.app/account#plans' }).then(function (result) {
                console.log(result);
                dis.$util.registerStat('payment', { subtype: 'intent', freq: price.type, price: price.price, place: dis.lugar })
            }).catch(function (error) {
                console.error(error);
            });
        },
        async gotoCheckout(planType) {
            const currentPlace = this.place || 'bc';
            const priceId = this.plans[currentPlace][planType];
            console.log('priceId', priceId)
            
            this.loading = true;
            let url = process.env.URL;
            const currentUrl = window.location.href;
            
            try {
                const get_stripe_checkout = functions.httpsCallable('get_stripe_checkout');
                const result = await get_stripe_checkout({
                    customer_id: this.getUser.stripe_details.stripe_customer_id,
                    price_id: priceId,
                    locale: this.$store.state.locale,
                    success_url: url + 'account#success',
                    cancel_url: currentUrl
                });

                this.$util.registerStat('payment', {
                    subtype: 'intent',
                    freq: planType,
                    price: priceId,
                    place: currentPlace
                });

                const stripe = await loadStripe(stripe_public);
                await stripe.redirectToCheckout({ sessionId: result.data.id });
            } catch (error) {
                console.error('Error during checkout:', error);
            } finally {
                this.loading = false;
            }
        },
        async gotoCheckout_orig(product) {
            var dis = this
            if (!dis.getUser.stripe_details.stripe_customer_id) return alert('Stripe ID missing. Please refresh. Contact support')
            let price = product.plans[product.plans.length > 1 && this.annual ? 1 : 0]
            this.loading = true
            let url = process.env.URL
            const get_stripe_checkout = functions.httpsCallable('get_stripe_checkout');
            await get_stripe_checkout({ customer_id: dis.getUser.stripe_details.stripe_customer_id, price_id: price.plan_id, locale: dis.$store.state.locale, success_url: url + 'account#success', cancel_url: url + 'account#plans' })
                .then(function (result) {
                    dis.$util.registerStat('payment', { subtype: 'intent', freq: price.type, price: price.price, place: dis.lugar })
                    var stripe = Stripe(stripe_public);
                    stripe.redirectToCheckout({ sessionId: result.data.id }).catch(error => { console.log(error) })

                    dis.loading = false
                }).catch(error => {
                    console.log(error)
                })
        },
        resetIframe() {
            this.showIframe = false;
            this.$nextTick(() => {
                this.showIframe = true;
            });
        },
        onIframeLoad() {
            this.iframeLoaded = true;
        }
    }
}
</script>

<style scoped>
.switch-button {
    text-transform: uppercase;
}

.right-active .switch-option-right span,
.left-active .switch-option-left span {
    color: white !important;
}

#pricing.left .price.mensual,
#pricing.right .price.anual {
    display: none;
}

.pricing-important span {
    display: block;
}

.pricing-important {
    font-size: 12px !important;
}
</style>
<template >
<div class="tarjeta_edit_wrapper" style="min-height: 645px;" @click.stop="doThat" >
<div class="tarjeta_bg animated fadeIn">
    <div v-if="preview.style.info.fondo.active" class="tarjeta_bg_container" >
        <div class="tarjeta_bg_logo" style="position:relative;" :style="{'animation-name':preview.style.info.fondo.animacion?'spin':'none'}">
            <img :src='$util.getBackImage(preview,null)' width="100%" id="tarjetaBgLogo">
        </div>
    </div>
</div>
<div class="tarjeta_evento_wrapper tarjeta_section current_view" id="tarjetaInfo" >
<div style="position: relative;">
        <div class="tarjeta_header mb25">
        <div class="tarjeta_foto animated fadeInDown" style="-webkit-animation-delay: .4s; animation-delay: .4s;">
            <img :src="$util.getLincImage(preview,null)" class="w-100" />
        </div>
        <h3 v-if="preview.info.nombre.length>0" class="tarjeta_nombre animated flipInX" style="-webkit-animation-delay: .5s; animation-delay: .5s;">{{preview.info.nombre}}</h3>
        <h3 v-else class="tarjeta_nombre animated flipInX" style="text-transform:uppercase;-webkit-animation-delay: .5s; animation-delay: .5s;">{{$util.isLoyaltyCustomizer()?$t('loyaltys.nombrecc'):$t('info.nombrecc')}}</h3>
        
        <h6 v-if="preview.info.trabajo.length>0" class="tarjeta_profesion animated fadeInUp" >{{preview.info.trabajo}}</h6>
        <h6 v-else class="tarjeta_profesion animated fadeInUp" style="text-transform:uppercase;-webkit-animation-delay: .6s; animation-delay: .6s;">{{$util.isLoyaltyCustomizer()?$t('loyaltys.profes'):$t('info.profes')}}</h6>
        
        <div class="button_group animated fadeIn" style="-webkit-animation-delay: .4s; animation-delay: .4s;">
            <button type="button"   @click="switchComponent('previewQR_vcard');"  class="button_camera"><font-awesome-icon icon="qrcode"/></button>
        </div>
    </div>
    <div class="tarjeta_contacto">
    <div class="tarjeta_botones">
        <div class="row">
        <div class="col-12 animated flipInX" style="-webkit-animation-delay: .7s; animation-delay: .7s;">
            <button type="button"  v-if="isCalendarTop" @click="openUrl(preview.info.calendar)" class="btn btn_principal mb10"><font-awesome-icon :icon="['fas','calendar-plus']"/> {{$t('preview.booking')}}</button>
            <button type="button"  v-if="isMobilephone"  @click="openUrl('tel:'+preview.info.mobilephone)" class="btn btn_principal mb10"><font-awesome-icon icon="mobile-alt"/> {{$t('preview.llamarm')}}</button>
            <button type="button"  v-if="isFixed" @click="openUrl('tel:'+preview.info.phone)" class="btn btn_principal mb10"><font-awesome-icon icon="phone-volume"/> {{$t('preview.llamarf')}}</button>
            <button type="button"  v-if="isEmailTop" @click="openUrl('mailto:'+preview.info.email)" class="btn btn_principal mb10"><font-awesome-icon icon="envelope"/> {{$t('preview.envemnail')}} </button>
            <button type="button"  v-if="isWebsiteTop"  @click="openUrl(preview.info.url)" class="btn btn_principal mb10"><font-awesome-icon icon="globe"/> {{$t('preview.visitw')}}</button>
            <button type="button"  v-if="isWhatsappTop" @click="openUrl(getWhatsappUrl())" class="btn btn_principal mb10"><font-awesome-icon :icon="['fab','whatsapp']"/> {{$t('preview.chat')}}</button>
            <!-- ACA ARRANCO LA 2da Fila !-->
        </div>
        </div>
        <div v-if="is3layout"  class="row">
            <!--IZQ-->
            <div class="col-6 animated fadeInLeft" style="-webkit-animation-delay: .8s; animation-delay: .8s;">
                <button type="button" v-if="isEmailBottom" @click="openUrl('mailto:'+preview.info.email)" class="btn btn_secundario mb10"><font-awesome-icon icon="envelope"/> {{$t('preview.email')}}</button>
            </div>
            <!--DER-->
            <div class="col-6 animated fadeInRight" style="-webkit-animation-delay: .8s; animation-delay: .8s;">
                <button type="button"  v-if="isSmsBottom" @click="openUrl('tel:'+preview.info.mobilephone)" class="btn btn_secundario mb10"><font-awesome-icon icon="comment"/> {{$t('preview.sms')}}</button>
                <button type="button"  v-if="isWhatsappBottom" @click="openUrl(getWhatsappUrl())"   class="btn btn_secundario mb10"><font-awesome-icon :icon="['fab','whatsapp']"/> CHAT</button>
            </div>
        </div>
        <div v-else  class="row">
            <div class="col-12 animated flipInX" style="-webkit-animation-delay: .7s; animation-delay: .7s;">
                <button type="button"  v-if="isWhatsappBottom" @click="openUrl(getWhatsappUrl())"   class="btn btn_secundario mb10"><font-awesome-icon :icon="['fab','whatsapp']"/> CHAT</button>
                <button type="button" v-if="isEmailBottom" @click="openUrl('mailto:'+preview.info.email)" class="btn btn_secundario mb10"><font-awesome-icon icon="envelope"/> {{$t('preview.envemnail')}} </button>
                <button type="button"  v-if="isWebsiteBottom" @click="openUrl(preview.info.url)" class="btn btn_secundario mb10"><font-awesome-icon icon="globe"/> {{$t('preview.visitw')}} </button>
                <button type="button"  v-if="isCalendarBottom" @click="openUrl(preview.info.calendar)" class="btn btn_secundario mb10"><font-awesome-icon :icon="['fas','calendar-plus']"/> {{$t('preview.booking')}}</button>
            </div>
        </div>
        <ul class="tarjeta_redes animated delay">
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.website"><a  @click="openUrl('website',$util.sanitizar(preview.info.website,'http://'))" ><font-awesome-icon icon="globe" :style="{ color: 'white' }"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.facebook"><a @click="openUrl('facebook',$util.sanitizar(preview.info.facebook,'facebook'))" ><font-awesome-icon :icon="['fab','facebook-f']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.twitter"><a  @click="openUrl('twitter',$util.sanitizar(preview.info.twitter,'twitter'))" ><font-awesome-icon :icon="['fab','x-twitter']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.linkedin"><a  @click="openUrl('linkedin',$util.sanitizar(preview.info.linkedin,'linkedin'))" ><font-awesome-icon :icon="['fab','linkedin']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.instagram"><a  @click="openUrl('instagram',$util.sanitizar(preview.info.instagram,'instagram'))" ><font-awesome-icon :icon="['fab','instagram']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.youtube"><a  @click="openUrl('youtube',$util.sanitizar(preview.info.youtube,'youtube'))" ><font-awesome-icon :icon="['fab','youtube']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.vimeo"><a  @click="openUrl('vimeo',$util.sanitizar(preview.info.vimeo,'vimeo'))" ><font-awesome-icon :icon="['fab','vimeo']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.skype"><a  @click="openUrl('skype','skype:'+preview.info.skype+'?call')" ><font-awesome-icon :icon="['fab','skype']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.behance"><a  @click="openUrl('behance',$util.sanitizar(preview.info.behance,'behance'))" ><font-awesome-icon :icon="['fab','behance']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.github"><a  @click="openUrl('github',$util.sanitizar(preview.info.github,'github'))" ><font-awesome-icon :icon="['fab','github']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.tiktok"><a  @click="openUrl('tiktok',$util.sanitizar(preview.info.tiktok,'tiktok'))" ><font-awesome-icon :icon="['fab','tiktok']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.spotify"><a  @click="openUrl($util.sanitizar(preview.info.spotify,'spotify'))" ><font-awesome-icon :icon="['fab','spotify']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.snapchat"><a  @click="openUrl('snapchat',$util.sanitizar(preview.info.snapchat,'snapchat'))" ><font-awesome-icon :icon="['fab','snapchat']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.line"><a  @click="openUrl('line',$util.sanitizar(preview.info.line,'line'))" ><font-awesome-icon :icon="['fab','line']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.telegram"><a  @click="openUrl('telegram',$util.sanitizar(preview.info.telegram,'telegram'))" ><font-awesome-icon :icon="['fab','telegram']"/></a></li>
                <li class="animated fadeInLeft" style="-webkit-animation-delay: .4s; animation-delay: .4s;" v-if="preview.info.googlemaps"><a @click="openUrl('googlemaps',$util.sanitizar(preview.info.googlemaps,'googlemaps'))" ><font-awesome-icon icon="map"/></a></li>
        </ul>
        <hr class="animated fadeIn" style="-webkit-animation-delay: .8s; animation-delay: .8s;">
    </div>
    </div>
    <div class="tarjeta_descripcion animated fadeInUp" style="-webkit-animation-delay: .9s; animation-delay: .9s;">
        <p v-if="preview.info.pie && preview.info.pie.length>0"  placeholder=""><pre>{{preview.info.pie}}</pre></p>
        <p v-else-if="$util.isCustomizer()" class="form-control" >{{$t('info.pieplace')}}</p>
    </div>
</div>
</div>  
    <div class="tarjeta_foot_wrapper">
        <div class="tarjeta_footer animated fadeInUp" v-show='preview.style.info.caja_logo.active' id="tarjetaFooter"> 
            <button type="button" @click="openUrl(preview.style.info.caja_logo.url)">
            <img :src="$util.getFooterImg(preview,null)"  class="w-100">
            </button>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:['preview','diruser'],
    data(){
        return{
            colors:'',
            dirUser:this.diruser,
            linc: this.preview,
            nombre: this.preview.info.nombre,
            trabajo: this.preview.info.trabajo
        }
        
    },
    methods: {
        isMe(){
            return true
        },
        openUrl(url) {
            if(url.includes('mailto:')){
                 url+='?subject='+this.$t('general.whatsmsg')
            }

            if(url.includes('mailto:') || url.includes('tel:')){
                window.location.href=url
            }else if (('standalone' in window.navigator) && window.navigator.standalone) {
                var win = window.open(url, '_top');
                win.focus();
            } else {
                var win = window.open(url, '_blank');
                win.focus();
            }
        },
        setSeo(){
            document.title=this.preview.info.nombre+' - '+this.preview.info.trabajo
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.href = this.preview.info.img
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        doThat(){
            if(this.$util.isCustomizer())alert(this.$t('notis.edicion'))
        },
        getWhatsappUrl(){
            if(!this.preview.info.mobilephone)return;
            let wp=this.preview.info.mobilephone;
            wp = wp.replace(/ /g, "")
            wp = wp.replace('+', "")
            return 'https://api.whatsapp.com/send?text='+this.$t('general.whatsmsg')+'&phone='+wp;
        },
        switchComponent(comp) {
            this.$bus.$emit("switchComp", comp);
        },
        gotoLogin(){
            window.open(process.env.URL+'login','_blank')
        },
   
    },
    computed: {
        getUrl(){
            if(process.client)return window.location
        },
        isMobilephone (){
            if(!this.preview.info.clayout)this.preview.info.clayout= 1
            var lay=this.preview.info.clayout;
            return lay==1 || lay==2 || lay==5 || lay==12;
        },
        isFixed(){
            var lay=this.preview.info.clayout;
            return lay==3 || lay==4 || lay==6;
        },
        isWhatsappTop(){
            var lay=this.preview.info.clayout;
            return lay==9;
        },
        isWhatsappBottom(){
            var lay=this.preview.info.clayout;
            return lay==1 || lay==4 || lay==10;
        },
        isEmailTop(){
            var lay=this.preview.info.clayout;
            return lay==7;
        },
        isEmailBottom(){
            var lay=this.preview.info.clayout;
            return lay==1 || lay==2 || lay==3 || lay==4 || lay==5 || lay==6 || lay==8 || lay==9 ||  lay==11 ;
        },
        isWebsiteTop(){
            var lay=this.preview.info.clayout;
            return lay==8;
        },
        isWebsiteBottom(){
            var lay=this.preview.info.clayout;
            return lay==7;
        },

        isSmsBottom(){
            var lay=this.preview.info.clayout;
            return lay==2 || lay==3;
        },
        is3layout(){
            var lay=this.preview.info.clayout;
            return lay<5;
        },
        isCalendarTop(){
            var lay=this.preview.info.clayout;
            return lay==10|| lay==11;
        },
        isCalendarBottom(){
            var lay=this.preview.info.clayout;
            return lay==12;
        },

        
    }
};
</script>

<style scoped>

ul.tarjeta_redes li:nth-child(n+6) {
    margin-top:20px !important;

}
#tarjetaInfo .btn_principal svg, #tarjetaInfo .btn_secundario svg {
    font-weight: 400;
    font-size: 19px;
    margin-right: 2px;
    position: relative;
    top: 1px;
}
.tarjeta_header .tarjeta_nombre {
    font-family: var(--info-nombre-family) !important;
    color:var(--info-nombre-color) !important;
    font-size: var(--info-nombre-size) !important;
    font-weight: var(--info-nombre-weight) !important;
}
.previewInfo .tarjeta_profesion {
    font-family: var(--info-cargo-family)!important;
    color:var(--info-cargo-color) !important;
    font-size: var(--info-cargo-size) !important;
    font-weight: var(--info-cargo-weight) !important;
}

.previewInfo .tarjeta_bg{
    background-color: var(--info-fondo-background) !important;
}

.previewInfo .tarjeta_bg .tarjeta_bg_logo{
    opacity: var(--info-fondo-opacity) !important;
    width: var(--info-fondo-width) !important;
    left:var(--info-fondo-left) !important;
    top:var(--info-fondo-top) !important;
}
#tarjetaInfo .btn_principal {
    font-family: var(--info-botones-family) !important;
    font-size: var(--info-botones-size) !important;
    font-weight: var(--info-botones-weight) !important;


    color:var(--info-botones_c1-color) !important;
    background-color: var(--info-botones_c1-background) !important;
}
#tarjetaInfo .btn_secundario {
    font-family: var(--info-botones-family) !important;
    font-size: var(--info-botones-size) !important;
    color:var(--info-botones_c2-color) !important;
    background-color: var(--info-botones_c2-background) !important;
    font-weight: var(--info-botones-weight) !important;
}
.tarjeta_redes a svg,
.tarjeta_redes button svg{
    font-size:var(--info-redes-size) !important;
    color:var(--info-redes-color) !important;
}
#tarjetaInfo .tarjeta_descripcion p,
#tarjetaInfo .tarjeta_descripcion p pre{
    font-family: var(--info-pie-family) !important;
    color:var(--info-pie-color) !important;
    line-height:var(--info-pie-lineh) !important;
    font-size: var(--info-pie-size) !important;
    font-weight: var(--info-pie-weight) !important;
    min-height:100px !important;
}
.previewInfo .tarjeta_footer{
    background-color: var(--info-caja_logo-background) !important;
}

.tarjeta_foot_wrapper .tarjeta_footer button{
    max-width:336px;
}

.tarjeta_content h1,
.tarjeta_content h2,
.tarjeta_content h3,
.tarjeta_content h4,
.tarjeta_content h5,
.tarjeta_content h6,
.tarjeta_content p,
.tarjeta_content pre{
  /*tmb fixea el info*/
    word-break: break-word !important;
}

pre{
 white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    overflow: auto;
    padding: 3px;
}

.tarjeta_wrapper .tarjeta_header .tarjeta_profesion ,
.tarjeta_wrapper .tarjeta_header .tarjeta_nombre {
    text-transform: none;
}


.tarjeta_foot_wrapper .tarjeta_footer a img{
    /*ema*/
    max-width: 255px;
    margin-left: auto;
    margin-right: auto;
}
#card .tarjeta_wrapper .tarjeta_header .tarjeta_foto{
    z-index:2;
}

#panel .button_qrcode{
display:none !important;
}
.button_qrcode svg,
.button_camera svg{
    font-size:22px;
    color:rgb(71, 71, 71);
}

</style>

<template>
    <div class="mb-3">
        <label>Icon</label>
        <div class="input-group mb-3" uib-dropdown>
            <input
                type="text"
                maxlength="50"
                class="form-control"
                placeholder="Search..."
                v-model="query"
            />
        </div>
        <div class="mb-12" style="
   height: 400px;
   overflow: scroll;">
            <Icon
                v-for="icon of icons.slice(0, 400)"
                :icon="icon"
                :class="isIcon(icon) ? 'selectd manito' : 'normal manito'"
                @click.native="pick(icon)"
            />
        </div>
    </div>
</template>

<script>
import { listIcons } from "@iconify/vue2";

export default {
    props: ["value", "multiple"],
    data() {
        let preselect = [];
        if(this.multiple){
            if(!this.value){
                preselect = []
            }else{
                preselect = this.value
            }
        }else{
            if(!this.value){
                preselect = []
            }else{
                preselect = [this.value]
            }
        }
        return {
            query: "",
            inputVal: this.value ? this.value  : "",
            icons: [...preselect, ...listIcons()],
            multiple: this.multiple,
        };
    },
    mounted() {},
    methods: {
        isIcon(icon) {
            if(this.multiple){
                return this.inputVal.includes(icon)
            }else{
                return this.inputVal === icon
            }
        },
        sections(icons) {
            return [...new Set(icons.map((el) => el.split(":")[0]))];
        },
        pick(icon) {
            if (this.multiple) {
                if (!Array.isArray(this.multiple)) {
                    this.multiple = [];
                }
                if (this.inputVal.includes(icon)) {
                    var index = this.inputVal.indexOf(icon);
                    if (index !== -1) {
                        this.inputVal.splice(index, 1);
                    }
                } else {
                    this.inputVal.push(icon);
                }
            } else {
                this.inputVal = icon;
            }
        },
    },
    watch: {
        query(val) {
            this.icons = [...this.value, ...listIcons().filter((el) => el.includes(val))];
        },
        inputVal(val) {
            this.$emit("input", val);
        },
    },
};
</script>

<style>
.selectd {
    font-size: 30px;
    margin: 5px;
    background: white;
    padding: 5px;
    border-radius: 4px;
}

.normal {
    font-size: 30px;
    margin: 5px;
    padding: 5px;
}
</style>
